import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum AutomationSelectorType {
    All = 'ALL',
    AnyButNotAll = 'ANY_BUT_NOT_ALL',
    Any = 'ANY',
    None = 'NONE',
}

export default AutomationSelectorType;

const labels: EnumDisplayLabels<typeof AutomationSelectorType> = {
    All: 'all of',
    AnyButNotAll: 'any but not all of',
    Any: 'any of',
    None: 'none of',
};

export const AutomationSelectorTypeDisplay = createDisplayEnum(AutomationSelectorType, labels);
