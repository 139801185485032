import { Tooltip } from '@mui/material';
import ErrorContent from 'components/ErrorContent/ErrorContent';
import LayoutBody from 'components/LayoutBody/LayoutBody';
import LayoutHeader from 'components/LayoutHeader/LayoutHeader';
import MyButton from 'components/MyButton/MyButton';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import authApi from 'features/auth/auth.api';
import AutomationType from 'features/auth/enums/AutomationType';
import {
    AutomationAction,
    AutomationConfig,
    AutomationConstraint,
} from 'features/auth/models/AutomationConfig';
import { AutomationSet } from 'features/auth/models/AutomationSet';
import SalesItemStatusBadge from 'features/sales/components/SalesItemStatusBadge/SalesItemStatusBadge';
import SalesOrderStatusBadge from 'features/sales/components/SalesOrderStatusBadge/SalesOrderStatusBadge';
import salesApi from 'features/sales/sales.api';
import WorkItemStatusBadge from 'features/workOrders/components/WorkItemStatusBadge/WorkItemStatusBadge';
import WorkItemStepStatusBadge from 'features/workOrders/components/WorkItemStepStatusBadge/WorkItemStepStatusBadge';
import workstationsApi from 'features/workstations/workstations.api';
import Icons from 'Icons';
import React, { useMemo, useState } from 'react';
import './AutomationsPage.scss';

export default function AutomationsPage() {
    const query = authApi.useAutomationConfigsQuery();
    const salesOrderStatusesQuery = salesApi.useSalesOrderStatusesQuery();
    const salesItemStatusesQuery = salesApi.useSalesItemStatusesQuery();

    const isLoading =
        query.isLoading || salesOrderStatusesQuery.isLoading || salesItemStatusesQuery.isLoading;
    const isError =
        query.isError || salesOrderStatusesQuery.isError || salesItemStatusesQuery.isError;

    return (
        <>
            <LayoutHeader>
                <PageHeader
                    title="Settings"
                    titleContext="Automations"
                />
            </LayoutHeader>
            <LayoutBody className="AutomationsPage__Body">
                {isLoading ? (
                    <MyLinearProgress />
                ) : isError ? (
                    <ErrorContent align="left" />
                ) : !query.data?.length ? (
                    <div className="AutomationsPage__Empty">
                        No automations are currently configured
                    </div>
                ) : (
                    <div className="AutomationsPage__AutomationsContainer">
                        {query.data?.map(s => (
                            <AutomationSetDisplay
                                key={s.id}
                                model={s}
                            />
                        ))}
                    </div>
                )}
            </LayoutBody>
        </>
    );
}

function AutomationSetDisplay({ model }: { model: AutomationSet }) {
    return (
        <div className="AutomationsPage__AutomationSetDisplay">
            <PropertyContainer
                layout="row"
                className="AutomationsPage__AutomationSetDisplay__Header"
            >
                <PropertyDisplay
                    label="Type"
                    value={model.type}
                />
                <PropertyDisplay
                    label="Id"
                    value={model.id}
                />
            </PropertyContainer>

            {model.data.map((cfg, i) => (
                <AutomationConfigRow
                    key={i}
                    config={cfg}
                    setType={model.type}
                />
            ))}
        </div>
    );
}

function AutomationConfigRow({ config, setType }: { config: AutomationConfig; setType: string }) {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className="AutomationsPage__AutomationRow">
            <PropertyContainer layout="table">
                <PropertyDisplay
                    label="Type"
                    value={
                        <>
                            <pre className="AutomationsPage__AutomationRow__Type">
                                {config.type}
                            </pre>
                            {setType !== config.type && (
                                <Tooltip
                                    title={`Does not match parent type '${setType}'. This automation may not run correctly.`}
                                    placement="top"
                                    arrow
                                >
                                    <span className="AutomationsPage__AutomationRow__TypeWarning">
                                        <Icons.Warning />
                                    </span>
                                </Tooltip>
                            )}
                        </>
                    }
                />
                <PropertyDisplay
                    label="When"
                    value={<TriggerDisplay model={config} />}
                />
                <PropertyDisplay
                    label="If"
                    verticalAlign="top"
                    value={
                        <>
                            {config.constraint.map((c, i) => (
                                <ConstraintDisplay
                                    key={i}
                                    constraint={c}
                                />
                            ))}
                        </>
                    }
                />

                <PropertyDisplay
                    label="Action"
                    verticalAlign="top"
                    value={<ActionDisplay action={config.action} />}
                />
            </PropertyContainer>

            <MyButton
                label={isExpanded ? 'Hide details ⏶' : 'Show details ⏷'}
                onClick={() => setIsExpanded(!isExpanded)}
                buttonType="LinkButton"
                size="small"
            />
            {isExpanded && (
                <pre className="AutomationsPage__JsonContent">
                    {JSON.stringify(config, null, 4)}
                </pre>
            )}
        </div>
    );
}

function TriggerDisplay({ model }: { model: AutomationConfig }) {
    const entityName = useMemo(() => {
        switch (model.type) {
            case AutomationType.SalesOrderToSalesOrderLine:
                return 'Sales order';
            case AutomationType.SalesOrderLineToSalesOrder:
                return 'Sales item';
            case AutomationType.WorkItemStepsToSalesOrderLine:
                return 'Work item step';
            default:
                return null;
        }
    }, [model]);

    const triggerName = useMemo(() => {
        switch (model.trigger) {
            case 'ANY':
                return 'is updated';
            case 'UPDATE_STATUS':
            case 'UPDATE_STATE':
                return 'status changes';
            case 'CANCEL':
                return 'is cancelled';
            default:
                return null;
        }
    }, [model]);

    return (
        <>
            {!entityName || !triggerName ? (
                <>Unknown trigger</>
            ) : (
                <>
                    {entityName} {triggerName}
                </>
            )}
        </>
    );
}

function ConstraintDisplay({ constraint }: { constraint: AutomationConstraint }) {
    switch (constraint.type) {
        case 'sales-order-status':
            return (
                <div className="AutomationsPage__ConstraintDisplay">
                    Sales order status is
                    {constraint.statusIds.map((id, i) => (
                        <SalesOrderStatusBadge
                            key={i}
                            statusId={id}
                            size="small"
                        />
                    ))}
                </div>
            );
        case 'sales-order-status-not':
            return (
                <div className="AutomationsPage__ConstraintDisplay">
                    Sales order status is anything but{' '}
                    {constraint.notStatusIds.map((id, i) => (
                        <SalesOrderStatusBadge
                            key={i}
                            statusId={id}
                            size="small"
                        />
                    ))}
                </div>
            );
        case 'sales-order-line-status':
            return (
                <div className="AutomationsPage__ConstraintDisplay">
                    Sales item status is
                    {constraint.lineStatusIds.map((id, i) => (
                        <SalesItemStatusBadge
                            key={i}
                            statusId={id}
                            size="small"
                        />
                    ))}
                </div>
            );
        case 'agg-sales-order-line-status':
            return (
                <div className="AutomationsPage__ConstraintDisplay">
                    {constraint.selectorType === 'ALL'
                        ? 'All related sales items have status'
                        : constraint.selectorType === 'ANY'
                        ? 'Any related sales items have status'
                        : constraint.selectorType === 'NONE'
                        ? 'No related sales items have status'
                        : constraint.selectorType === 'ANY_BUT_NOT_ALL'
                        ? 'Some but not all related sales items have status'
                        : 'Unknown selector type'}{' '}
                    {constraint.statusIds.map((id, i) => (
                        <SalesItemStatusBadge
                            key={i}
                            statusId={id}
                            size="small"
                        />
                    ))}
                </div>
            );
        case 'sales-order-line-status-not':
            return (
                <div className="AutomationsPage__ConstraintDisplay">
                    Sales item status is anything but{' '}
                    {constraint.notStatusIds.map((id, i) => (
                        <SalesItemStatusBadge
                            key={i}
                            statusId={id}
                            size="small"
                        />
                    ))}
                </div>
            );
        case 'work-order-item-step-state':
            return (
                <div className="AutomationsPage__ConstraintDisplay">
                    Work item step status is
                    {constraint.workOrderItemStepState.map((s, i) => (
                        <WorkItemStepStatusBadge
                            key={i}
                            status={s}
                            displayLocked
                        />
                    ))}
                </div>
            );

        case 'work-order-item-state':
            return (
                <div className="AutomationsPage__ConstraintDisplay">
                    Work item status is
                    {constraint.workOrderItemState.map((s, i) => (
                        <WorkItemStatusBadge
                            key={i}
                            status={s}
                            badgeType="pill"
                        />
                    ))}
                </div>
            );
        case 'sales-order-line-status-work-item-step':
            return (
                <>
                    <div className="AutomationsPage__ConstraintDisplay">
                        Step is
                        <WorkflowStepDisplay stepId={constraint.workflowStepId} />
                        with status
                        {constraint.workOrderItemStepStates.map((s, i) => (
                            <WorkItemStepStatusBadge
                                key={i}
                                status={s}
                                displayLocked
                            />
                        ))}
                    </div>
                </>
            );

        default:
            return null;
    }
}

function WorkflowStepDisplay({ stepId }: { stepId: string }) {
    const query = workstationsApi.useWorkflowStepListQuery();
    const step = query.data?.find(s => s.id === stepId);

    return (
        <>
            {query.isLoading ? (
                <MyLinearProgress />
            ) : step?.name ? (
                <strong>{step.name}</strong>
            ) : (
                <em>Unknown step</em>
            )}
        </>
    );
}

function ActionDisplay({ action }: { action: AutomationAction }) {
    const label =
        action.type === 'sales-order-status'
            ? 'Set sales order status to'
            : 'Set sales item status to';

    const badge =
        action.type === 'sales-order-status' ? (
            <SalesOrderStatusBadge
                statusId={action.newStatusId}
                size="small"
            />
        ) : (
            <SalesItemStatusBadge
                statusId={action.newStatusId}
                size="small"
            />
        );

    return (
        <div className="AutomationsPage__ActionDisplay">
            <span>{label}</span>
            {badge}
        </div>
    );
}
