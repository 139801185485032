import z from 'zod';
import AutomationType from '../enums/AutomationType';
import { AutomationConfigSchema } from './AutomationConfig';

export const AutomationSetSchema = z.object({
    id: z.string(),
    type: z.nativeEnum(AutomationType),
    data: z.array(AutomationConfigSchema),
});

export type AutomationSet = z.infer<typeof AutomationSetSchema>;
