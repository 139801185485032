enum FeatureFlag {
    Accounting = 'accounting',
    AllowDisPatch = 'allow_dispatch',
    CostPrice = 'cost_price',
    Dispatch = 'dispatch',
    Receiving = 'receiving',
    ProductOptionsNew = 'product_options_new',
    SalesOrderCoreStatusBadgeColors = 'sales_order_core_status_badge_colors',
}

export default FeatureFlag;
