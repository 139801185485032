import Icons from 'Icons';
import StatusPill from 'components/StatusPill/StatusPill';
import WorkItemStepTransitionReason, {
    WorkItemStepTransitionReasonDisplay,
} from 'features/workOrders/enums/WorkItemStepTransitionReason';
import WorkItemStepUiState, {
    WorkItemStepUiStateDisplay,
} from 'features/workOrders/enums/WorkItemStepUiState';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './WorkItemStepUiStatusBadge.scss';

export default function WorkItemStepUiStatusBadge({
    uiState,
    lastTransitionReason,
    badgeType = 'pill',
    className,
}: {
    uiState: WorkItemStepUiState;
    lastTransitionReason: WorkItemStepTransitionReason | null;
    badgeType?: 'pill' | 'icon' | 'text' | 'title';
    className?: string;
}) {
    const WrapperComponnt = badgeType === 'pill' ? StatusPill : 'div';
    return (
        <WrapperComponnt
            className={coalesceClassNames(
                'WorkItemStepUiStatusBadge',
                `WorkItemStepUiStatusBadge--${badgeType}`,
                className,
                uiState,
            )}
            size="small"
        >
            {badgeType !== 'pill' && <StatusIcon uiState={uiState} />}
            {badgeType !== 'icon' && (
                <div className="WorkItemStepUiStatusBadge__Label">
                    {/* show the reason if we have it, otherwise show the current status */}
                    {lastTransitionReason
                        ? WorkItemStepTransitionReasonDisplay.display(lastTransitionReason)
                        : WorkItemStepUiStateDisplay.display(uiState)}
                </div>
            )}
        </WrapperComponnt>
    );
}

function StatusIcon({ uiState }: { uiState: WorkItemStepUiState }) {
    return (
        <div className={coalesceClassNames('WorkItemStepUiStatusBadge__Icon', uiState)}>
            {uiState === WorkItemStepUiState.Completed ? (
                <Icons.StepComplete />
            ) : uiState === WorkItemStepUiState.InProgress ? (
                <Icons.StepStart />
            ) : uiState === WorkItemStepUiState.OnHold ? (
                <Icons.StepPause />
            ) : null}
        </div>
    );
}
