import React, { useMemo } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { isEmpty } from 'utils/helpers';
import './PropertyDisplay.scss';

export default function PropertyDisplay({
    label,
    hint,
    inlineHint,
    value,
    options,
    className,
    verticalAlign = 'middle',
}: {
    label: string;
    hint?: React.ReactNode;
    inlineHint?: React.ReactNode;
    value?: React.ReactNode;
    options?: { label: string; value: string }[];
    className?: string;
    verticalAlign?: 'top' | 'middle';
}) {
    const displayValue = useMemo(() => {
        if (Array.isArray(value)) {
            const labels = options
                ? value.map(v => options?.find(opt => opt.value === String(v))?.label)
                : value;
            return labels.join(', ');
        }
        return options?.find(opt => opt.value === String(value))?.label || value;
    }, [value, options]);

    return (
        <div
            className={coalesceClassNames(
                'PropertyDisplay',
                verticalAlign === 'top' && `PropertyDisplay--verticalAlign-top`,
                className,
            )}
        >
            <div className="PropertyDisplay__Label">
                <span className="PropertyDisplay__Label__Text">
                    {label}
                    {inlineHint && (
                        <>
                            {' '}
                            <span className="PropertyDisplay__Label__Text__InlineHint">
                                {inlineHint}
                            </span>
                        </>
                    )}
                </span>

                {hint && (
                    <>
                        {' '}
                        <span className="PropertyDisplay__Label__Hint">{hint}</span>
                    </>
                )}
            </div>
            {isEmpty(value) ? (
                <div className="PropertyDisplay__Value empty">
                    <span className="PropertyDisplay__Value__Text">&ndash;</span>
                </div>
            ) : (
                <div className="PropertyDisplay__Value">
                    <span className="PropertyDisplay__Value__Text">{displayValue}</span>
                </div>
            )}
        </div>
    );
}
