import { DateTime } from 'luxon';
import { dateFilterOptionsAny, DateFilterValuesPast } from 'models/DateFilterOption';
import { DynamicQueryParams } from 'models/DynamicQueryParams';
import { api, ApiTagType } from 'services/api';
import { v4 as uuidv4 } from 'uuid';
import z from 'zod';
import {
    DealerDocumentsResult,
    DealerDocumentsResultSchema,
} from '../documents/models/DealerDocumentsResult';
import { DocumentUploadResultDocument } from '../documents/models/DocumentUploadResult';
import {
    ManufacturerDocumentsResult,
    ManufacturerDocumentsResultSchema,
} from '../documents/models/ManufacturerDocumentsResult';
import SalesItemUpdateError from './enums/SalesItemUpdateError';
import { SalesItem } from './models/SalesItem';
import {
    SalesItemHistoryResult,
    SalesItemHistoryResultSchema,
} from './models/SalesItemHistoryResult';
import { SalesItemLabelConfig, SalesItemLabelConfigSchema } from './models/SalesItemLabelConfig';
import {
    SalesItemOptionsLegacy,
    SalesItemOptionsLegacySchema,
} from './models/SalesItemOptionsLegacy';
import { SalesOrder, SalesOrderSchema } from './models/SalesOrder';
import { SalesOrderDetail, SalesOrderDetailSchema } from './models/SalesOrderDetail';
import { SalesOrderGenerateWorksheetsResultOldSchema } from './models/SalesOrderGenerateWorksheetsResultOld';
import {
    SalesOrderHistoryResult,
    SalesOrderHistoryResultSchema,
} from './models/SalesOrderHistoryResult';
import { SalesOrderLineStatus, SalesOrderLineStatusSchema } from './models/SalesOrderLineStatus';
import { SalesOrderSplitResult, SalesOrderSplitResultSchema } from './models/SalesOrderSplitResult';
import { SalesOrderStatus, SalesOrderStatusSchema } from './models/SalesOrderStatus';
import { SalesOrderWorkItem, SalesOrderWorkItemSchema } from './models/SalesOrderWorkItem';
import {
    SalesOrderWorkItemsResult,
    SalesOrderWorkItemsResultSchema,
} from './models/SalesOrderWorkItemsResult';
import { SalesOrderWorksheet, SalesOrderWorksheetSchema } from './models/SalesOrderWorksheet';
import {
    SalesOrderWorkStepSummaryResult,
    SalesOrderWorkStepSummaryResultSchema,
} from './models/SalesOrderWorkStepSummary';

export type SalesOrderListParams = DynamicQueryParams<{
    search: string;
    dateReceived: DateFilterValuesPast | null;
    statusId: number | null;
    isArchived: boolean | null;
}>;

export type SalesOrderHistoryParams = DynamicQueryParams<{
    manufacturerOrderId: number;
}>;

export type SalesItemHistoryParams = DynamicQueryParams<{
    itemId: number;
    manufacturerOrderId: number;
}>;

const salesApi = api.injectEndpoints({
    endpoints: build => ({
        salesOrderList: build.query<{ data: SalesOrder[]; total: number }, SalesOrderListParams>({
            query: args => {
                const dateFilter = dateFilterOptionsAny.find(
                    o => o.value === args.criteria.dateReceived,
                );
                const dateFrom = dateFilter?.minDate.toISO({ includeOffset: false }) ?? null;
                const dateTo = dateFilter?.maxDate.toISO({ includeOffset: false }) ?? null;
                return {
                    url: `/salesorder/search`,
                    method: 'POST',
                    data: {
                        search: args.criteria.search,
                        dateFrom,
                        dateTo,
                        status: args.criteria.statusId || undefined,
                        isArchived: args.criteria.isArchived,
                        meta: {
                            skip: args.paging.skip,
                            limit: args.paging.limit,
                            sortBy: args.sort?.propertyKey,
                            sortDir: args.sort?.direction,
                        },
                    },
                };
            },
            transformResponse: response => {
                const schema = z.object({
                    data: z.array(SalesOrderSchema),
                    total: z.number(),
                });
                return schema.parse(response);
            },
            providesTags: [ApiTagType.SalesOrder],
        }),

        salesOrderDetail: build.query<SalesOrderDetail, string>({
            query: id => ({
                url: `/salesorder/${id}`,
                method: 'GET',
            }),
            transformResponse: result => SalesOrderDetailSchema.parse(result),
            providesTags: (res, err, id) => [{ type: ApiTagType.SalesOrder, id }],
        }),

        salesItemOptionsLegacy: build.query<
            SalesItemOptionsLegacy,
            { itemId: number; manufacturerOrderId: number }
        >({
            query: args => ({
                url: `/old/manufacturer-order-lines/${args.itemId}/inventory-options`,
                method: 'GET',
            }),
            transformResponse: result => {
                const schema = z.object({
                    data: SalesItemOptionsLegacySchema,
                });
                return schema.parse(result).data;
            },
            providesTags: (res, err, args) => [
                { type: ApiTagType.SalesOrder, id: args.manufacturerOrderId },
            ],
        }),

        /** Load sales order status values */
        salesOrderStatuses: build.query<SalesOrderStatus[], void>({
            query: () => ({
                url: `/salesorder/statuses`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => {
                const arr = z.array(SalesOrderStatusSchema).parse(result);
                arr?.sort((a, b) => a.sortOrder - b.sortOrder);
                return arr;
            },
        }),

        /** Load order item status values */
        salesItemStatuses: build.query<SalesOrderLineStatus[], void>({
            query: () => ({
                url: `/salesorderitems/statuses`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => {
                const arr = z.array(SalesOrderLineStatusSchema).parse(result);
                arr?.sort((a, b) => a.sortOrder - b.sortOrder);
                return arr;
            },
        }),

        /** Get history records for sales order */
        salesOrderHistory: build.query<SalesOrderHistoryResult, SalesOrderHistoryParams>({
            query: params => ({
                url:
                    `/old/trackers?` +
                    `arguments%5Btype%5D%5B%5D=order-update` +
                    `&arguments%5Btype%5D%5B%5D=order-line-split` +
                    `&arguments%5Btype%5D%5B%5D=order-dl` +
                    `&arguments%5Bmodel%5D=ManufacturerOrder` +
                    `&arguments%5Bid%5D=${params.criteria.manufacturerOrderId}` +
                    `&arguments%5Bevent_item%5D=` +
                    `&arguments%5Bfilters%5D%5Bsearch_text%5D=` +
                    `&pagination%5Boffset%5D=${params.paging.skip}` +
                    `&pagination%5Blimit%5D=${params.paging.limit}`,
                method: 'GET',
            }),
            transformResponse: result => SalesOrderHistoryResultSchema.parse(result),
            providesTags: (res, err, params) => [
                { type: ApiTagType.SalesOrder, id: params.criteria.manufacturerOrderId },
                { type: ApiTagType.SalesOrderHistory, id: params.criteria.manufacturerOrderId },
            ],
        }),

        /** Get history records for order item */
        salesItemHistory: build.query<SalesItemHistoryResult, SalesItemHistoryParams>({
            query: params => ({
                url:
                    `/old/trackers?` +
                    `arguments%5Btype%5D%5B%5D=order-line` +
                    `&arguments%5Btype%5D%5B%5D=order-line-dl` +
                    `&arguments%5Bmodel%5D=ManufacturerOrderLine` +
                    `&arguments%5Bid%5D=${params.criteria.itemId}` +
                    `&arguments%5Bevent_item%5D=` +
                    `&arguments%5Bfilters%5D%5Bsearch_text%5D=` +
                    `&pagination%5Boffset%5D=${params.paging.skip}` +
                    `&pagination%5Blimit%5D=${params.paging.limit}`,
                method: 'GET',
            }),
            transformResponse: result => SalesItemHistoryResultSchema.parse(result),
            providesTags: (res, err, params) => [
                { type: ApiTagType.SalesOrder, id: params.criteria.manufacturerOrderId },
                { type: ApiTagType.SalesItemHistory, id: params.criteria.itemId },
            ],
        }),

        /** Get sales order documents (uploaded by manufacturer) */
        salesOrderDocuments: build.query<ManufacturerDocumentsResult, number>({
            query: manufacturerOrderId => ({
                url: `/old/orders/${manufacturerOrderId}/documents`,
                method: 'GET',
            }),
            transformResponse: result => ManufacturerDocumentsResultSchema.parse(result),
            providesTags: (res, err, id) => [
                { type: ApiTagType.SalesOrder, id },
                { type: ApiTagType.SalesOrderDocument, id },
            ],
        }),

        /** Get sales order documents (uploaded by dealer) */
        salesOrderDealerDocuments: build.query<DealerDocumentsResult, number>({
            query: manufacturerOrderId => ({
                url: `/old/orders/${manufacturerOrderId}/dealer-documents`,
                method: 'GET',
            }),
            transformResponse: result => DealerDocumentsResultSchema.parse(result),
            providesTags: (res, err, id) => [{ type: ApiTagType.SalesOrder, id }],
        }),

        /** Attach an uploaded document to a sales order */
        salesOrderDocumentAttach: build.mutation<
            void,
            {
                document: DocumentUploadResultDocument;
                manufacturerOrderId: number;
            }
        >({
            query: args => ({
                url: `/old/orders/${args.manufacturerOrderId}/documents`,
                method: 'POST',
                data: {
                    data: [args.document],
                    directUpload: true,
                    is_image_upload: true, // always true?
                    model: 'Order',
                    model_id: '',
                    type: null,
                },
            }),
            invalidatesTags: (req, res, args) => [
                { type: ApiTagType.SalesOrderDocument, id: args.manufacturerOrderId },
            ],
        }),

        /** Archive document */
        salesOrderDocumentArchive: build.mutation<
            void,
            {
                manufacturerOrderId: number;
                documentId: number;
            }
        >({
            query: args => ({
                url: `/old/orders/${args.manufacturerOrderId}/documents/${args.documentId}`,
                method: 'POST',
                data: {
                    model: 'Order',
                    model_id: '',
                    _method: 'DELETE',
                },
            }),
            invalidatesTags: (req, res, args) => [
                { type: ApiTagType.SalesOrderDocument, id: args.manufacturerOrderId },
            ],
        }),

        /** Sales Order generate labels - New version */
        salesOrderGenerateLabels: build.mutation<
            string,
            {
                order: SalesOrder;
                item?: SalesItem;
                labelIds: number[];
                copies: number | null;
            }
        >({
            query: ({ order, item, labelIds, copies }) => ({
                url: `/salesorder/legacy/${order.legacyId}/labels`,
                method: 'POST',
                data: {
                    copies,
                    labelIds,
                    salesOrderLineIds: item ? [item.id] : null,
                },
            }),
            transformResponse: result => {
                if (typeof result === 'string') {
                    // BE just returns a string if something goes wrong
                    // This should be changed in the BE to make it nicer
                    throw new Error(result);
                }
                const schema = z.object({
                    file: z.string(),
                });
                return schema.parse(result).file;
            },
        }),

        salesOrderLabelConfig: build.query<SalesItemLabelConfig[], number>({
            query: orderId => ({
                url: `/old/labels/label-config/sales-order/${orderId}`,
                method: 'GET',
            }),
            transformResponse: response => {
                const schema = z.object({
                    data: z.array(SalesItemLabelConfigSchema),
                });
                return schema.parse(response).data;
            },
        }),

        salesItemLabelConfig: build.query<SalesItemLabelConfig[], number>({
            query: salesItemId => ({
                url: `/old/labels/label-config/sales-order-line/${salesItemId}`,
                method: 'GET',
            }),
            transformResponse: response => {
                const schema = z.object({
                    data: z.array(SalesItemLabelConfigSchema),
                });
                return schema.parse(response).data;
            },
        }),

        salesItemWorkflowStepLabelConfig: build.query<
            SalesItemLabelConfig[],
            { salesItemId: string; workflowStepId: string }
        >({
            query: ({ salesItemId, workflowStepId }) => ({
                url: `/old/labels/label-config/sales-order-line/${salesItemId}/workflow-step/${workflowStepId}`,
                method: 'GET',
            }),
            transformResponse: response => {
                const schema = z.object({
                    data: z.array(SalesItemLabelConfigSchema),
                });
                return schema.parse(response).data;
            },
        }),

        /** Order Item generate labels - Old version - to be removed soon
         * Replaced by salesOrderGenerateLabelsNew with optional itemId param
         */
        salesItemGenerateLabelsOld: build.mutation<
            string,
            {
                order: SalesOrderDetail;
                line: SalesItem;
                labelIds: number[];
                copies: number | null;
            }
        >({
            query: ({ order, line, labelIds, copies }) => {
                // generate a uuid to be used in all items
                const uuid = uuidv4();

                return {
                    url: `/old/orders/ordering/label/generate-order-labels`,
                    method: 'POST',
                    data: {
                        brand_id: order.context.customerPurchaseOrder.brandId,
                        category_id: order.context.customerPurchaseOrder.categoryId,
                        count: 1,
                        copies,
                        first_run: true,
                        label_type: 'standard',
                        manufacturer_order_id: order.legacyId,
                        manufacturer_order_line_id: line.id,
                        labelIds,
                        order_id: line.context.orderWindow.orderId,
                        order_window_id: line.orderWindowId,
                        order_window_product_id: line.orderWindowProductId,
                        print_all: true,
                        // product_measurement_other_id:"",
                        purchase_order_id: null,
                        quantity: line.quantity,
                        split_id: order.context.customerPurchaseOrder.splitId,
                        total: order.context.orderLines.length,
                        type: 'product',
                        uuid,
                    },
                };
            },
            transformResponse: result => {
                if (typeof result === 'string') {
                    // BE just returns a string if something goes wrong
                    // This should be changed in the BE to make it nicer
                    throw new Error(result);
                }
                const schema = z.object({
                    file: z.string(),
                });
                return schema.parse(result).file;
            },
        }),

        /** Sales order generate worksheets */
        salesOrderGenerateWorksheets: build.mutation<SalesOrderWorksheet[], SalesOrder>({
            query: order => ({
                url: `/salesorder/legacy/${order.legacyId}/worksheets`,
                method: 'POST',
            }),
            transformResponse: data => {
                const schema = z.array(SalesOrderWorksheetSchema);
                return schema.parse(data);
            },
        }),

        /** Order Item generate worksheets
         * TODO implement new route for this to match the SalesOrderGenerateWorksheets route
         */
        salesItemGenerateWorksheets: build.mutation<SalesOrderWorksheet[], SalesItem>({
            query: item => ({
                url: `/old/manufacturer-order-lines/generate/${item.id}`,
                method: 'POST',
                data: {
                    id: item.id,
                    manufacturer_order_id: item.manufacturerOrderId,
                    line_number: item.lineNumber,
                },
            }),
            transformResponse: data => {
                const resultOld = SalesOrderGenerateWorksheetsResultOldSchema.parse(data);
                const resultNew: SalesOrderWorksheet[] = Object.entries(resultOld.data.xls).map(
                    ([key, val]) => ({
                        name: val.error_message === undefined ? val.name : key,
                        url: val.error_message === undefined ? val.url : undefined,
                        errorMessage: val.error_message,
                    }),
                );
                return resultNew;
            },
        }),

        /** Write to Sales order log */
        salesOrderWriteLog: build.mutation<
            void,
            {
                manufacturerOrderId: number;
                manufacturerReference: string;
                action: string;
                download: boolean;
                downloadType: string;
            }
        >({
            query: args => ({
                url: `/old/manufacturer-orders/${args.manufacturerOrderId}`,
                method: 'POST',
                data: {
                    action: args.action,
                    download: args.download,
                    download_type: args.downloadType,
                    manufacturer_reference: args.manufacturerReference,
                    _method: 'PUT',
                },
            }),
            invalidatesTags: (req, res, params) => [
                { type: ApiTagType.SalesOrderHistory, id: params.manufacturerOrderId },
            ],
        }),

        /** Write to order item log */
        salesItemWriteLog: build.mutation<
            void,
            {
                id: number;
                itemNumber: number;
                action: string;
                download: boolean;
                downloadType: string;
            }
        >({
            query: args => ({
                url: `/old/manufacturer-order-lines/${args.id}`,
                method: 'POST',
                data: {
                    action: args.action,
                    download: args.download,
                    download_type: args.downloadType,
                    line_number: args.itemNumber,
                    _method: 'PUT',
                },
            }),
            invalidatesTags: (req, res, params) => [
                { type: ApiTagType.SalesItemHistory, id: params.id },
            ],
        }),

        /** Update sales order */
        salesOrderUpdate: build.mutation<
            {
                success: boolean;
                errorCode?: SalesItemUpdateError;
            },
            SalesOrder
        >({
            async queryFn(order, _queryApi, _extraOptions, fetchWithBQ) {
                const result = await fetchWithBQ({
                    url: `/salesorder/${order.id}`,
                    method: 'POST',
                    data: {
                        orderStatusId: order.orderStatusId,
                        eta: order.eta,
                        freightOverride: order.totalFreightOverride,
                        trackingInformation: order.trackingInformation,
                        internalNotes: order.internalNotes,
                        externalNotes: order.externalNotes,
                        taxValidated: order.taxValidated,
                        allowDispatch: order.allowDispatch,
                        userDefinedFields: order.userDefinedFields,
                    },
                });

                if (result.error) {
                    return {
                        data: {
                            success: false,
                            errorCode: result.error.response.data?.code,
                        },
                    };
                }
                return {
                    data: {
                        success: true,
                    },
                };
            },
            invalidatesTags: (req, res, order) => [
                ApiTagType.SalesOrder,
                { type: ApiTagType.SalesOrder, id: order.id },
            ],
        }),

        /** Update sales item
         * if using for bulk update then set invalidateTags to false
         * and manually invalidate when you're done */
        salesItemUpdate: build.mutation<
            {
                success: boolean;
                errorCode?: SalesItemUpdateError;
            },
            {
                item: SalesItem;
                invalidateTags?: boolean;
            }
        >({
            async queryFn({ item }, _queryApi, _extraOptions, fetchWithBQ) {
                const result = await fetchWithBQ({
                    url: `/salesorderitems/${item.id}`,
                    method: 'POST',
                    data: {
                        quantity: item.quantity,
                        orderLineStatusId: item.orderLineStatusId,
                        notes: item.notes,
                        unitCostPrice: item.unitCostPrice,
                        unitSellPrice: item.unitSellPrice,
                        unitTax: item.unitTax,
                    },
                });

                if (result.error) {
                    return {
                        data: {
                            success: false,
                            errorCode: result.error.response.data?.code,
                        },
                    };
                }
                return {
                    data: {
                        success: true,
                    },
                };
            },
            invalidatesTags: (req, res, { invalidateTags = true }) =>
                invalidateTags ? [ApiTagType.SalesOrder] : [],
        }),

        salesOrderResyncProductData: build.mutation<void, SalesOrder>({
            query: order => ({
                url: `/salesorder/legacy/${order.legacyId}/refreshkmats`,
                method: 'POST',
            }),
            invalidatesTags: [ApiTagType.SalesOrder],
        }),

        /** Archive sales order */
        salesOrderArchive: build.mutation<void, SalesOrder>({
            query: order => ({
                url: `/old/manufacturer-orders/archive`,
                method: 'POST',
                data: {
                    archived_date: DateTime.now().toUTC().toISO(), // .toFormat('yyyy-MM-dd HH:mm:ss'),
                    ids: [order.legacyId],
                },
            }),
            invalidatesTags: [ApiTagType.SalesOrder],
        }),

        salesOrderUnarchive: build.mutation<void, SalesOrder>({
            query: order => ({
                url: `/old/manufacturer-orders/unarchive`,
                method: 'POST',
                data: {
                    ids: [order.legacyId],
                },
            }),
            invalidatesTags: [ApiTagType.SalesOrder],
        }),

        /** Cancel sales order */
        salesOrderCancel: build.mutation<void, { orderId: string; statusId: number }>({
            query: args => ({
                url: `/salesorder/${args.orderId}/cancel`,
                method: 'POST',
                data: {
                    orderStatusId: args.statusId,
                },
            }),
            invalidatesTags: [ApiTagType.SalesOrder],
        }),

        /** Get work item statuses by sales order id */
        salesOrderWorkItems: build.query<SalesOrderWorkItemsResult, number>({
            query: salesOrderId => ({
                url: `/salesorder/legacy/${salesOrderId}/workorderitems`,
                method: 'GET',
            }),
            transformResponse: result => SalesOrderWorkItemsResultSchema.parse(result),
            providesTags: (res, err, id) => [
                { type: ApiTagType.SalesOrder, id },
                ApiTagType.WorkItem,
            ],
        }),

        /** Get work items and steps by sales order item id */
        salesItemWorkItems: build.query<
            SalesOrderWorkItem[],
            { itemId: number; manufacturerOrderId: number }
        >({
            query: args => ({
                url: `/salesorderitems/${args.itemId}/workorderitems`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => z.array(SalesOrderWorkItemSchema).parse(result),
            providesTags: (res, err, args) => [
                { type: ApiTagType.SalesOrder, id: args.manufacturerOrderId },
                ApiTagType.WorkItem,
            ],
        }),

        /** Get a hash of sales items to work step summary */
        salesOrderWorkStepSummary: build.query<SalesOrderWorkStepSummaryResult, string>({
            query: salesOrderId => ({
                url: `/salesorder/${salesOrderId}/workitemstepsummary`,
                method: 'GET',
            }),
            transformResponse: result => SalesOrderWorkStepSummaryResultSchema.parse(result),
            providesTags: (res, err, id) => [
                { type: ApiTagType.SalesOrder, id },
                ApiTagType.WorkItem,
            ],
        }),

        salesOrderSplit: build.mutation<
            SalesOrderSplitResult,
            { orderId: number; orderItems: number[] }
        >({
            query: args => ({
                url: `/old/manufacturer-orders/${args.orderId}/split-single`,
                method: 'POST',
                data: {
                    lineIds: args.orderItems,
                },
            }),
            transformResponse: result => SalesOrderSplitResultSchema.parse(result),
            invalidatesTags: [ApiTagType.SalesOrder],
        }),

        salesOrderGenerateConfirmation: build.query<string, string>({
            async queryFn(id, _queryApi, _extraOptions, fetchWithBQ) {
                const result = await fetchWithBQ({
                    url: `/salesorder/${id}/generateorderconfirmation`,
                    method: 'GET',
                });
                if (result.error) {
                    if (result.error.response.status === 501) {
                        // 501 is returned when the account is not configured to generate order confirmations
                        // return an empty string to indicate that the feature is not available
                        // component should then display a message to the user
                        return {
                            data: '',
                        };
                    }
                    return {
                        error: result.error,
                    };
                }
                const schema = z.object({
                    url: z.string(),
                });

                return {
                    data: schema.parse(result.data).url,
                };
            },
        }),

        salesOrderGeneratePackingSlip: build.query<string, string>({
            async queryFn(id, _queryApi, _extraOptions, fetchWithBQ) {
                const result = await fetchWithBQ({
                    url: `/salesorder/${id}/generate-packing-slip`,
                    method: 'GET',
                });
                if (result.error) {
                    if (result.error.response.status === 501) {
                        // 501 is returned when the account is not configured to generate order confirmations
                        // return an empty string to indicate that the feature is not available
                        // component should then display a message to the user
                        return {
                            data: '',
                        };
                    }
                    return {
                        error: result.error,
                    };
                }
                const schema = z.object({
                    url: z.string(),
                });

                return {
                    data: schema.parse(result.data).url,
                };
            },
        }),
    }),
});

export default salesApi;
