import { Tooltip } from '@mui/material';
import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import ErrorContent from 'components/ErrorContent/ErrorContent';
import HelpIcon from 'components/HelpIcon/HelpIcon';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import MyModal from 'components/MyModal/MyModal';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import { WorkItemStepStatusDisplay } from 'features/workOrders/enums/WorkItemStepStatus';
import { computeWorkItemStepUiState } from 'features/workOrders/enums/WorkItemStepUiState';
import { WorkItemStepBasic } from 'features/workOrders/models/WorkItemStepBasic';
import { WorkOrderItemStepHistory } from 'features/workstations/models/WorkOrderItemStepHistory';
import { WorkstationBasic } from 'features/workstations/models/WorkstationBasic';
import workstationsApi from 'features/workstations/workstations.api';
import Icons from 'Icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { formatDateTimeRelative } from 'utils/dateHelpers';
import WorkItemStepUiStatusBadge from '../WorkItemStepUiStatusBadge/WorkItemStepUiStatusBadge';
import './WorkItemStepHistoryModal.scss';

const COLUMNS = ColumnBuilder<WorkOrderItemStepHistory>()
    .column({
        label: 'Date',
        key: 'timestamp',
        defaultSort: 'DESC',
        isSortable: true,
        getValue: item => item.timestamp,
        renderValue: val => formatDateTimeRelative(val),
    })
    .column({
        label: 'Status',
        key: 'state',
        getValue: item => WorkItemStepStatusDisplay.display(item.state),
    })
    .column({
        label: 'User',
        key: 'user',
        getValue: item => `${item.context.principal.firstName} ${item.context?.principal.lastName}`,
    })
    .column({
        label: 'Workstation',
        key: 'workstation',
        emptyDash: true,
        getValue: item => item.context.workstation?.name,
    })
    .build();

export default function WorkItemStepHistoryModal({
    model,
    close,
}: {
    model: WorkItemStepBasic;
    close?: () => void;
}) {
    const historyQuery = workstationsApi.useWorkItemStepHistoryQuery(model.id);
    const workstationsQuery = workstationsApi.useWorkItemStepWorkstationCandidatesQuery(model.id);

    return (
        <MyModal
            className="WorkItemStepHistoryModal"
            close={close}
            containerSelector="#modal-root-top"
            mobileTitle="Work Item"
            header={
                <PageHeader
                    title="Step History"
                    titleContext={model.context.workflowStep.name}
                />
            }
        >
            <PropertyContainer layout="table">
                <PropertyDisplay
                    label="Work item"
                    value={model.context.workOrderItem.tuid}
                />
                <PropertyDisplay
                    label="Item description"
                    value={model.context.workOrderItem.description}
                />
                <PropertyDisplay
                    label="Status"
                    value={
                        <div className="WorkItemStepHistoryModal__Status">
                            <WorkItemStepUiStatusBadge
                                uiState={computeWorkItemStepUiState(
                                    model.status,
                                    model.context.canTransition,
                                )}
                                lastTransitionReason={model.lastTransitionReason ?? null}
                                badgeType="pill"
                            />
                            {model.context.workstationLatest && (
                                <>
                                    <Icons.ArrowNext />
                                    <div className="WorkItemStepHistoryModal__Status__Workstation">
                                        {model.context.workstationLatest?.name}
                                    </div>
                                </>
                            )}
                        </div>
                    }
                />
            </PropertyContainer>

            <div className="PropertyContainerSpacer" />

            <div className="WorkItemStepHistoryModal__Workstations">
                <div className="WorkItemStepHistoryModal__Workstations__Title">
                    Workstation candidates{' '}
                    <HelpIcon tooltipTitle="List of workstations that can action this step. This is based on the workflow of the item and the group assigned to the work order" />
                </div>
                {workstationsQuery.isLoading ? (
                    <div className="WorkItemStepHistoryModal__Workstations__Loading">
                        <MyLinearProgress />
                    </div>
                ) : workstationsQuery.isError ? (
                    <ErrorContent align="left" />
                ) : workstationsQuery.data?.length === 0 ? (
                    <div className="WorkItemStepHistoryModal__Workstations__Empty">
                        There are no workstations that can action this step
                    </div>
                ) : (
                    <ul className="WorkItemStepHistoryModal__Workstations__List">
                        {workstationsQuery.data?.map(w => (
                            <WorkstationLinkItem
                                key={w.id}
                                workstation={w}
                                step={model}
                            />
                        ))}
                    </ul>
                )}
            </div>

            <h2 className="WorkItemStepHistoryModal__HistoryTitle">History</h2>
            <DataTable
                className="WorkItemStepHistoryModal__HistoryTable"
                data={historyQuery.data}
                isLoading={historyQuery.isLoading}
                isError={historyQuery.isError}
                columns={COLUMNS}
                onRefresh={historyQuery.refetch}
                isRefreshing={historyQuery.isFetching}
                emptyState="No history records found"
                zebra="light"
                showHeader="full"
            />
        </MyModal>
    );
}

function WorkstationLinkItem({
    workstation,
    step,
}: {
    workstation: WorkstationBasic;
    step: WorkItemStepBasic;
}) {
    const href = `/workstations/${workstation.id}/${step.id}`;
    return (
        <li className="WorkItemStepHistoryModal__WorkstationLinkItem">
            <Icons.ChevronRight />
            <Link
                className="Link"
                to={href}
            >
                {workstation.name}
            </Link>
            {step.context.workstationLatest?.id === workstation.id && (
                <Tooltip
                    title="Step is currently assigned to this workstation"
                    placement="top"
                    arrow
                >
                    <span className="WorkItemStepHistoryModal__WorkstationLinkItem__CurrentIcon">
                        <Icons.Star />
                    </span>
                </Tooltip>
            )}
        </li>
    );
}
