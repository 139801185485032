import PropertyEditCheckbox from 'components/PropertyEditCheckbox/PropertyEditCheckbox';
import PropertyEditColor from 'components/PropertyEditColor/PropertyEditColor';
import PropertyEditDate from 'components/PropertyEditDate/PropertyEditDate';
import PropertyEditMultiSelect from 'components/PropertyEditMultiSelect/PropertyEditMultiSelect';
import PropertyEditNumber from 'components/PropertyEditNumber/PropertyEditNumber';
import PropertyEditSelect from 'components/PropertyEditSelect/PropertyEditSelect';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import UserDefinedFieldValueType from 'enums/UserDefinedFieldValueType';
import { UserDefinedField } from 'models/UserDefinedField';
import React, { useMemo } from 'react';

export default function UdfEdit({
    udf,
    value,
    readonly = false,
    disabled = false,
    hint = '(user-defined)',
    onChange,
}: {
    udf: UserDefinedField;
    value?: string | string[] | null;
    readonly?: boolean;
    disabled?: boolean;
    hint?: string;
    onChange?: (val: string | string[]) => void;
}) {
    const options = useMemo(
        () =>
            udf.validationData.options?.map(opt => ({
                label: opt,
                value: opt,
            })),
        [udf],
    );

    const allowMultiple = useMemo(
        () =>
            udf.validationData.maxSelectedOptions
                ? udf.validationData.maxSelectedOptions > 1
                : false,
        [udf],
    );

    const arrayValue = useMemo(() => {
        if (Array.isArray(value)) {
            return value;
        }
        return value ? [value] : [];
    }, [value]);

    const stringValue = useMemo(() => {
        if (Array.isArray(value)) {
            return value[0];
        }
        return value ?? '';
    }, [value]);

    const selectCustomValidation = useMemo(() => {
        if (allowMultiple) {
            const min = udf.validationData.minSelectedOptions || 0;
            const max = udf.validationData.maxSelectedOptions || 1;
            const vals = arrayValue;
            if (vals.length < min) {
                return `Please select at least ${min} ${min === 1 ? 'option' : 'options'}`;
            }
            if (vals.length > max) {
                return `Select a maximum of ${max} ${max === 1 ? 'option' : 'options'}`;
            }
        }
        return '';
    }, [
        allowMultiple,
        arrayValue,
        udf.validationData.maxSelectedOptions,
        udf.validationData.minSelectedOptions,
    ]);

    switch (udf.valueType) {
        case UserDefinedFieldValueType.Boolean:
            return (
                <PropertyEditCheckbox
                    key={udf.id}
                    label={udf.name}
                    checked={stringValue === 'true'}
                    onChange={val => onChange?.(`${val}`)}
                    readonly={readonly}
                    disabled={disabled}
                    validationRequired={udf.validationData.required && `${udf.name} is required`}
                />
            );
        case UserDefinedFieldValueType.Decimal:
        case UserDefinedFieldValueType.Int: {
            const numValue =
                udf.valueType === UserDefinedFieldValueType.Decimal
                    ? parseFloat(stringValue)
                    : parseInt(stringValue, 10);
            return (
                <PropertyEditNumber
                    key={udf.id}
                    label={udf.name}
                    hint={hint}
                    value={numValue}
                    onChange={val => onChange?.(`${val}`)}
                    readonly={readonly}
                    disabled={disabled}
                    min={udf.validationData.minValue}
                    max={udf.validationData.maxValue}
                    allowDecimals={udf.valueType === UserDefinedFieldValueType.Decimal}
                    allowBlank={!udf.validationData.required}
                    validationRequired={udf.validationData.required}
                />
            );
        }
        case UserDefinedFieldValueType.Option:
            return allowMultiple ? (
                <PropertyEditMultiSelect
                    key={udf.id}
                    label={udf.name}
                    hint={hint}
                    value={arrayValue}
                    onChange={val => onChange?.(val)}
                    options={options}
                    allowBlank={true}
                    readonly={readonly}
                    disabled={disabled}
                    validationRequired={udf.validationData.required && `${udf.name} is required`}
                    validationCustom={selectCustomValidation}
                />
            ) : (
                <PropertyEditSelect
                    key={udf.id}
                    label={udf.name}
                    hint={hint}
                    value={stringValue}
                    onChange={val => onChange?.([val])}
                    options={options}
                    allowBlank={true}
                    readonly={readonly}
                    disabled={disabled}
                    validationRequired={udf.validationData.required && `${udf.name} is required`}
                    validationCustom={selectCustomValidation}
                />
            );
        case UserDefinedFieldValueType.Color:
            return (
                <PropertyEditColor
                    key={udf.id}
                    label={udf.name}
                    hint={hint}
                    value={stringValue}
                    onChange={val => onChange?.(`${val}`)}
                    readonly={readonly}
                    disabled={disabled}
                    validationRequired={udf.validationData.required && `${udf.name} is required`}
                />
            );
        case UserDefinedFieldValueType.Date:
            return (
                <PropertyEditDate
                    key={udf.id}
                    label={udf.name}
                    hint={hint}
                    value={stringValue}
                    onChange={val => onChange?.(val ?? '')}
                    readonly={readonly}
                    disabled={disabled}
                    validationRequired={udf.validationData.required && `${udf.name} is required`}
                />
            );
        case UserDefinedFieldValueType.File:
        case UserDefinedFieldValueType.Id:
        case UserDefinedFieldValueType.String:
        default:
            return (
                <PropertyEditText
                    key={udf.id}
                    label={udf.name}
                    hint={hint}
                    value={stringValue}
                    onChange={onChange}
                    readonly={readonly}
                    disabled={disabled}
                    maxLength={udf.validationData.maxLength}
                    validationRequired={udf.validationData.required && `${udf.name} is required`}
                    validationRegex={
                        udf.validationData.regex
                            ? [
                                  udf.validationData.regex,
                                  `Please enter a valid value for ${udf.name}`,
                              ]
                            : undefined
                    }
                />
            );
    }
}
