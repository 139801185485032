import WorkItemStatus from 'features/workOrders/enums/WorkItemStatus';
import WorkItemStepStatus from 'features/workOrders/enums/WorkItemStepStatus';
import { z } from 'zod';
import AutomationSelectorType from '../enums/AutomationSelectorType';
import AutomationType from '../enums/AutomationType';

export const AutomationConstraintSchema = z.discriminatedUnion('type', [
    z.object({
        type: z.literal('sales-order-status'),
        statusIds: z.array(z.number()),
    }),
    z.object({
        type: z.literal('sales-order-line-status'),
        lineStatusIds: z.array(z.number()),
    }),
    z.object({
        type: z.literal('sales-order-line-status-not'),
        notStatusIds: z.array(z.number()),
    }),
    z.object({
        type: z.literal('agg-sales-order-line-status'),
        statusIds: z.array(z.number()),
        selectorType: z.nativeEnum(AutomationSelectorType),
    }),
    z.object({
        type: z.literal('sales-order-status-not'),
        notStatusIds: z.array(z.number()),
    }),
    z.object({
        type: z.literal('work-order-item-step-state'),
        workOrderItemStepState: z.array(z.nativeEnum(WorkItemStepStatus)),
    }),
    z.object({
        type: z.literal('work-order-item-state'),
        workOrderItemState: z.array(z.nativeEnum(WorkItemStatus)),
    }),
    z.object({
        type: z.literal('sales-order-line-status-work-item-step'),
        workflowStepId: z.string(),
        workOrderItemStepStates: z.array(z.nativeEnum(WorkItemStepStatus)),
        selectorType: z.nativeEnum(AutomationSelectorType), // TODO QuantitySelector?
    }),
]);

export type AutomationConstraint = z.infer<typeof AutomationConstraintSchema>;

export const AutomationActionSchema = z.object({
    type: z.enum(['sales-order-status', 'sales-order-line-status']),
    newStatusId: z.number(),
});

export type AutomationAction = z.infer<typeof AutomationActionSchema>;

export const AutomationConfigSchema = z.discriminatedUnion('type', [
    z.object({
        type: z.literal(AutomationType.SalesOrderToSalesOrderLine),
        trigger: z.enum(['ANY', 'UPDATE_STATUS', 'CANCEL']),
        constraint: z.array(AutomationConstraintSchema),
        action: AutomationActionSchema,
    }),
    z.object({
        type: z.literal(AutomationType.SalesOrderLineToSalesOrder),
        trigger: z.enum(['UPDATE_STATUS']),
        constraint: z.array(AutomationConstraintSchema),
        action: AutomationActionSchema,
    }),
    /** Represents specific actions that will be triggered automatically when work order item step's state changes. */
    z.object({
        type: z.literal(AutomationType.WorkItemStepsToSalesOrderLine),
        trigger: z.enum(['UPDATE_STATE']),
        constraint: z.array(AutomationConstraintSchema),
        action: AutomationActionSchema,
    }),
]);

export type AutomationConfig = z.infer<typeof AutomationConfigSchema>;
