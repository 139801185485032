import { CircularProgress } from '@mui/material';
import StatusPill, { StatusPillSize } from 'components/StatusPill/StatusPill';
import FeatureFlag from 'enums/FeatureFlag';
import salesApi from 'features/sales/sales.api';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { useFeatureFlagEnabled } from 'utils/posthogHelpers';
import { SalesOrderStatus } from '../../models/SalesOrderStatus';
import './SalesOrderStatusBadge.scss';

export default function SalesOrderStatusBadge({
    statusId,
    status,
    size = 'medium',
    className,
}: {
    statusId?: number;
    status?: SalesOrderStatus;
    size?: StatusPillSize;
    className?: string;
}) {
    const query = salesApi.useSalesOrderStatusesQuery(undefined, { skip: !!status });
    const statusToRender = status ?? query.data?.find(s => s.id === statusId);

    const useCoreStatusColors = useFeatureFlagEnabled(FeatureFlag.SalesOrderCoreStatusBadgeColors);

    return status == null && query.isLoading ? (
        <CircularProgress size={28} />
    ) : (
        <StatusPill
            className={coalesceClassNames(
                'SalesOrderStatusBadge',
                statusToRender?.coreStatus,
                className,
            )}
            size={size}
            style={
                useCoreStatusColors
                    ? undefined
                    : {
                          backgroundColor: statusToRender?.backgroundColor,
                          borderColor: statusToRender?.backgroundColor,
                          color: statusToRender?.textColor,
                      }
            }
        >
            {statusToRender ? statusToRender.name : statusId}
        </StatusPill>
    );
}
