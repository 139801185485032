import { faker } from '@faker-js/faker/locale/af_ZA';
import WorkItemStepStatus from 'features/workOrders/enums/WorkItemStepStatus';
import AutomationSelectorType from '../enums/AutomationSelectorType';
import AutomationType from '../enums/AutomationType';
import { AutomationSet } from '../models/AutomationSet';

export const fakeAutomations: AutomationSet[] = [
    {
        id: faker.string.uuid(),
        type: AutomationType.SalesOrderLineToSalesOrder,
        data: [
            {
                type: AutomationType.SalesOrderLineToSalesOrder,
                action: {
                    type: 'sales-order-status',
                    newStatusId: 1,
                },
                trigger: 'UPDATE_STATUS',
                constraint: [
                    {
                        type: 'agg-sales-order-line-status',
                        statusIds: [2],
                        selectorType: AutomationSelectorType.Any,
                    },
                    {
                        type: 'sales-order-status',
                        statusIds: [3],
                    },
                ],
            },
            {
                // this one has wrong type and should be flagged with a warning in the UI
                type: AutomationType.SalesOrderToSalesOrderLine,
                action: {
                    type: 'sales-order-line-status',
                    newStatusId: 4,
                },
                trigger: 'UPDATE_STATUS',
                constraint: [
                    {
                        type: 'sales-order-line-status',
                        lineStatusIds: [5],
                    },
                    {
                        type: 'sales-order-status',
                        statusIds: [1],
                    },
                ],
            },
            {
                type: AutomationType.SalesOrderLineToSalesOrder,
                action: {
                    type: 'sales-order-status',
                    newStatusId: 1,
                },
                trigger: 'UPDATE_STATUS',
                constraint: [
                    {
                        type: 'agg-sales-order-line-status',
                        statusIds: [2],
                        selectorType: AutomationSelectorType.All,
                    },
                    {
                        type: 'sales-order-status',
                        statusIds: [1, 4],
                    },
                ],
            },
            {
                type: AutomationType.SalesOrderLineToSalesOrder,
                action: {
                    type: 'sales-order-status',
                    newStatusId: 3,
                },
                trigger: 'UPDATE_STATUS',
                constraint: [
                    {
                        type: 'agg-sales-order-line-status',
                        statusIds: [4, 5],
                        selectorType: AutomationSelectorType.Any,
                    },
                    {
                        type: 'sales-order-status',
                        statusIds: [1, 4],
                    },
                ],
            },
            {
                type: AutomationType.SalesOrderLineToSalesOrder,
                action: {
                    type: 'sales-order-status',
                    newStatusId: 4,
                },
                trigger: 'UPDATE_STATUS',
                constraint: [
                    {
                        type: 'agg-sales-order-line-status',
                        statusIds: [5],
                        selectorType: AutomationSelectorType.All,
                    },
                    {
                        type: 'sales-order-status',
                        statusIds: [1],
                    },
                ],
            },
            {
                type: AutomationType.SalesOrderLineToSalesOrder,
                action: {
                    type: 'sales-order-status',
                    newStatusId: 3,
                },
                trigger: 'UPDATE_STATUS',
                constraint: [
                    {
                        type: 'agg-sales-order-line-status',
                        statusIds: [4, 5],
                        selectorType: AutomationSelectorType.Any,
                    },
                    {
                        type: 'sales-order-status',
                        statusIds: [1],
                    },
                ],
            },
        ],
    },

    {
        id: faker.string.uuid(),
        type: AutomationType.WorkItemStepsToSalesOrderLine,
        data: [
            {
                type: AutomationType.WorkItemStepsToSalesOrderLine,
                action: {
                    type: 'sales-order-line-status',
                    newStatusId: 146,
                },
                trigger: 'UPDATE_STATE',
                constraint: [
                    {
                        type: 'sales-order-line-status',
                        lineStatusIds: [145],
                    },
                    {
                        type: 'work-order-item-step-state',
                        workOrderItemStepState: [WorkItemStepStatus.InProgress],
                    },
                ],
            },
            {
                type: AutomationType.WorkItemStepsToSalesOrderLine,
                action: {
                    type: 'sales-order-line-status',
                    newStatusId: 147,
                },
                trigger: 'UPDATE_STATE',
                constraint: [
                    {
                        type: 'agg-sales-order-line-status',
                        statusIds: [4, 5],
                        selectorType: AutomationSelectorType.All,
                    },
                    {
                        type: 'sales-order-line-status-work-item-step',
                        selectorType: AutomationSelectorType.All,
                        workflowStepId: 'd39c5dd8-85e5-401a-8073-3ca6396f8d19',
                        workOrderItemStepStates: [
                            WorkItemStepStatus.Completed,
                            WorkItemStepStatus.CompletedLocked,
                        ],
                    },
                ],
            },
            {
                type: AutomationType.WorkItemStepsToSalesOrderLine,
                action: {
                    type: 'sales-order-line-status',
                    newStatusId: 152,
                },
                trigger: 'UPDATE_STATE',
                constraint: [
                    {
                        type: 'sales-order-line-status',
                        lineStatusIds: [146],
                    },
                    {
                        type: 'sales-order-line-status-work-item-step',
                        selectorType: AutomationSelectorType.AnyButNotAll,
                        workflowStepId: 'd39c5dd8-85e5-401a-8073-3ca6396f8d19',
                        workOrderItemStepStates: [
                            WorkItemStepStatus.Completed,
                            WorkItemStepStatus.CompletedLocked,
                        ],
                    },
                ],
            },
            {
                type: AutomationType.WorkItemStepsToSalesOrderLine,
                action: {
                    type: 'sales-order-line-status',
                    newStatusId: 173,
                },
                trigger: 'UPDATE_STATE',
                constraint: [
                    {
                        type: 'sales-order-line-status',
                        lineStatusIds: [147],
                    },
                    {
                        type: 'sales-order-line-status-work-item-step',
                        selectorType: AutomationSelectorType.AnyButNotAll,
                        workflowStepId: 'f03d67f5-9357-47ce-ba45-367fe2150a75',
                        workOrderItemStepStates: [
                            WorkItemStepStatus.Completed,
                            WorkItemStepStatus.CompletedLocked,
                        ],
                    },
                ],
            },
            {
                type: AutomationType.WorkItemStepsToSalesOrderLine,
                action: {
                    type: 'sales-order-line-status',
                    newStatusId: 148,
                },
                trigger: 'UPDATE_STATE',
                constraint: [
                    {
                        type: 'sales-order-line-status',
                        lineStatusIds: [153, 147],
                    },
                    {
                        type: 'sales-order-line-status-work-item-step',
                        selectorType: AutomationSelectorType.All,
                        workflowStepId: 'f03d67f5-9357-47ce-ba45-367fe2150a75',
                        workOrderItemStepStates: [
                            WorkItemStepStatus.Completed,
                            WorkItemStepStatus.CompletedLocked,
                        ],
                    },
                ],
            },
        ],
    },
];
