import { CircularProgress } from '@mui/material';
import StatusPill from 'components/StatusPill/StatusPill';
import FeatureFlag from 'enums/FeatureFlag';
import { SalesOrderLineStatus } from 'features/sales/models/SalesOrderLineStatus';
import salesApi from 'features/sales/sales.api';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { useFeatureFlagEnabled } from 'utils/posthogHelpers';
import './SalesItemStatusBadge.scss';

export default function SalesItemStatusBadge({
    statusId,
    status,
    size = 'medium',
    className,
}: {
    statusId?: number;
    status?: SalesOrderLineStatus;
    size?: 'small' | 'medium';
    className?: string;
}) {
    const query = salesApi.useSalesItemStatusesQuery();
    const statusToRender = status ?? query.data?.find(s => s.id === statusId);

    const useCoreStatusColors = useFeatureFlagEnabled(FeatureFlag.SalesOrderCoreStatusBadgeColors);

    return status == null && query.isLoading ? (
        <CircularProgress size={20} />
    ) : (
        <StatusPill
            className={coalesceClassNames('SalesItemStatusBadge', className)}
            style={
                useCoreStatusColors
                    ? undefined
                    : {
                          backgroundColor: statusToRender?.backgroundColor,
                          borderColor: statusToRender?.backgroundColor,
                          color: statusToRender?.textColor,
                      }
            }
            size={size}
        >
            {statusToRender ? statusToRender.name : statusId}
        </StatusPill>
    );
}
