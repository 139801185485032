import Icons from 'Icons';
import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import MyMenuButton from 'components/MyMenuButton/MyMenuButton';
import WorkItemStepUiStatusBadge from 'features/workOrders/components/WorkItemStepUiStatusBadge/WorkItemStepUiStatusBadge';
import { computeWorkItemStepUiState } from 'features/workOrders/enums/WorkItemStepUiState';
import { WorkstationWorkItemField } from 'features/workOrders/models/WorkstationWorkItem';
import useStepFilters from 'features/workstations/hooks/useStepFilters';
import { WorkStationStepSummary } from 'features/workstations/models/WorkstationStepSummary';
import { selectCurrentWorkstation } from 'features/workstations/workstations.slice';
import React, { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { isEmpty } from 'utils/helpers';
import WorkItemFieldDisplay from '../WorkItemFieldDisplay/WorkItemFieldDisplay';
import './WorkstationStepsTable.scss';

export type WorkstationStepsTableStep = WorkStationStepSummary & {
    isCollapsed?: boolean;
};

export default function WorkstationStepsTable({
    data,
    onRefresh,
    isRefreshing = false,
    isLoading = false,
    isError = false,
    showColIdentifier = true,
    showColSummaryFields = true,
    showColStatus = true,
    showColFilter = true,
    onRowClick,
}: {
    data?: WorkstationStepsTableStep[];
    onRefresh?: () => void;
    isRefreshing?: boolean;
    isLoading?: boolean;
    isError?: boolean;
    showColTuid?: boolean;
    showColIdentifier?: boolean;
    showColSummaryFields?: boolean;
    showColStatus?: boolean;
    showColFilter?: boolean;
    onRowClick?: (item: WorkstationStepsTableStep) => void;
}) {
    const currentWorkstation = useAppSelector(selectCurrentWorkstation);

    // get the currently open step id from the route params
    // if a step is selected it will be highlighted in the table
    const { stepId: activeStepId } = useParams();

    const columns = useMemo(
        () =>
            ColumnBuilder<WorkStationStepSummary>()
                .column(
                    showColStatus && {
                        label: 'Status',
                        key: 'status',
                        className: 'WorkstationStepsTable__StatusColumn',
                        render: item => <StatusCell item={item} />,
                    },
                )
                .column({
                    label: 'Item / Step Name',
                    key: 'tuid',
                    className: 'WorkstationStepsTable__LabelValueColumn',
                    render: item => (
                        <LabelValueCell
                            label={item.context.workOrderItem.tuid}
                            value={item.context.workflowStep.name}
                        />
                    ),
                })
                .column(
                    showColIdentifier && {
                        label: 'Ref',
                        key: 'identifier',
                        className: 'WorkstationStepsTable__LabelValueColumn',
                        getValue: item => item.context.workOrderItem.identifier,
                        renderValue: val =>
                            val ? (
                                <LabelValueCell
                                    label="Ref"
                                    value={val}
                                />
                            ) : null,
                    },
                )
                .column(
                    showColSummaryFields && {
                        label: '',
                        key: 'field0',
                        className: 'WorkstationStepsTable__LabelValueColumn',
                        render: item => {
                            const field = item.context.workOrderItem.summaryFields[0];
                            return <SummaryField field={field} />;
                        },
                    },
                )
                .column(
                    showColSummaryFields && {
                        label: '',
                        key: 'field1',
                        className: 'WorkstationStepsTable__LabelValueColumn',
                        render: item => {
                            const field = item.context.workOrderItem.summaryFields[1];
                            return <SummaryField field={field} />;
                        },
                    },
                )
                .column(
                    showColSummaryFields && {
                        label: '',
                        key: 'field2',
                        className: 'WorkstationStepsTable__LabelValueColumn',
                        render: item => {
                            const field = item.context.workOrderItem.summaryFields[2];
                            return <SummaryField field={field} />;
                        },
                    },
                )
                .column(
                    showColFilter && {
                        label: 'Filter',
                        key: 'filter',
                        className: 'WorkstationStepsTable__FilterColumn',
                        render: item => <FilterMenuButton item={item} />,
                    },
                )
                .build(),
        [showColIdentifier, showColSummaryFields, showColStatus, showColFilter],
    );

    // retain url query params when navigating
    const [params] = useSearchParams();

    // always clear the showMore param from WorkstationStepSidebar
    params.delete('showMore');

    return (
        <DataTable
            className="WorkstationStepsTable"
            zebra="light"
            data={data}
            isLoading={isLoading}
            isError={isError}
            rowLinkTo={item =>
                item.isCollapsed
                    ? ''
                    : `/workstations/${currentWorkstation?.id}/${item.id}?${params}`
            }
            onRefresh={onRefresh}
            isRefreshing={isRefreshing}
            columns={columns}
            showHeader="none"
            rowClass={item => (item.isCollapsed ? 'WorkstationStepsTable__DataRow--collapsed' : '')}
            rowIsHighlighted={item => activeStepId === item.id}
            onRowClick={onRowClick}
            emptyState={
                <>
                    <h2>Nothing to display</h2>
                    <p>No work items found</p>
                </>
            }
        />
    );
}

function StatusCell({ item }: { item: WorkStationStepSummary }) {
    const uiState = computeWorkItemStepUiState(item.status, item.context.canTransition);
    return (
        <WorkItemStepUiStatusBadge
            uiState={uiState}
            lastTransitionReason={item.lastTransitionReason ?? null}
        />
    );
}

function SummaryField({ field }: { field?: WorkstationWorkItemField }) {
    if (!field || (isEmpty(field.value) && field.hideIfEmpty)) {
        return null;
    }
    return (
        <LabelValueCell
            label={field.displayName || field.key}
            value={isEmpty(field.value) ? '' : <WorkItemFieldDisplay field={field} />}
        />
    );
}

function LabelValueCell({
    label,
    value,
}: {
    label?: string | null;
    value: React.ReactFragment | null;
}) {
    return (
        <div className="WorkstationStepsTable__LabelValueCell">
            <div className="WorkstationStepsTable__LabelValueCell__Label">{label}</div>
            <div className="WorkstationStepsTable__LabelValueCell__Value">
                {isEmpty(value) ? <span className="empty">&ndash;</span> : value}
            </div>
        </div>
    );
}

function FilterMenuButton({ item }: { item: WorkStationStepSummary }) {
    const summaryFields = item.context.workOrderItem.summaryFields;

    const { applyFilter } = useStepFilters();

    return (
        <MyMenuButton
            IconRight={Icons.Filter}
            buttonType="Nude"
            className="WorkstationStepsTable__FilterMenuButton"
            size="small"
            title="Filter more like this"
            menuItems={[
                {
                    label: (
                        <FilterMenuItemLabel
                            label="Work order item"
                            value={item.context.workOrderItem.tuid}
                        />
                    ),
                    buttonType: 'None',
                    onClick: () => applyFilter('tuid', item.context.workOrderItem.tuid),
                },
                !!item.context.workOrderItem.identifier && {
                    label: (
                        <FilterMenuItemLabel
                            label="Ref"
                            value={item.context.workOrderItem.identifier}
                        />
                    ),
                    buttonType: 'None',
                    onClick: () => applyFilter('ref', item.context.workOrderItem.identifier),
                },
                {
                    label: (
                        <FilterMenuItemLabel
                            label="Step"
                            value={item.context.workflowStep.name}
                        />
                    ),
                    buttonType: 'None',
                    onClick: () => applyFilter('step', item.context.workflowStep.name),
                },
                summaryFields[0] && {
                    label: (
                        <FilterMenuItemLabel
                            label={summaryFields[0].displayName || summaryFields[0].key}
                            value={`${summaryFields[0].value ?? ''}`}
                        />
                    ),
                    buttonType: 'None',
                    onClick: () =>
                        applyFilter('summaryFields', {
                            key: summaryFields[0].key,
                            displayName: summaryFields[0].displayName,
                            value: summaryFields[0].value,
                        }),
                },
                summaryFields[1] && {
                    label: (
                        <FilterMenuItemLabel
                            label={summaryFields[1].displayName || summaryFields[1].key}
                            value={`${summaryFields[1].value ?? ''}`}
                        />
                    ),
                    buttonType: 'None',
                    onClick: () =>
                        applyFilter('summaryFields', {
                            key: summaryFields[1].key,
                            displayName: summaryFields[1].displayName,
                            value: summaryFields[1].value,
                        }),
                },
                summaryFields[2] && {
                    label: (
                        <FilterMenuItemLabel
                            label={summaryFields[2].displayName || summaryFields[2].key}
                            value={`${summaryFields[2].value ?? ''}`}
                        />
                    ),
                    buttonType: 'None',
                    onClick: () =>
                        applyFilter('summaryFields', {
                            key: summaryFields[2].key,
                            displayName: summaryFields[2].displayName,
                            value: summaryFields[2].value,
                        }),
                },
            ]}
        />
    );
}

function FilterMenuItemLabel({ label, value }: { label: string; value: string | null }) {
    return (
        <div className="WorkstationStepsTable__FilterMenuItemLabel">
            <label>{label}</label>
            <span>{isEmpty(value) ? '-' : value}</span>
        </div>
    );
}
