import StatusPill, { StatusPillSize } from 'components/StatusPill/StatusPill';
import WorkItemStepStatus, {
    WorkItemStepStatusDisplay,
} from 'features/workOrders/enums/WorkItemStepStatus';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './WorkItemStepStatusBadge.scss';

export default function WorkItemStepStatusBadge({
    status,
    size = 'small',
    displayLocked = false,
}: {
    status: WorkItemStepStatus;
    size?: StatusPillSize;
    displayLocked?: boolean;
}) {
    const isLocked =
        status === WorkItemStepStatus.CompletedLocked ||
        status === WorkItemStepStatus.SkippedLocked;
    return (
        <StatusPill
            className={coalesceClassNames('WorkItemStepStatusBadge', status)}
            size={size}
        >
            {WorkItemStepStatusDisplay.display(status)}
            {displayLocked && isLocked && ' (locked)'}
        </StatusPill>
    );
}
